/* ==========================================================================
   #SELECT 2 CUSTOM STYLES
   ========================================================================== */

// sass-lint:disable-all

/**
 * Instead of modifying `_select2.scss` directly, we add Dashboard's custom
 * styles for select2 plugin here.
 *
 * The code is a littly messy because of the default select2 css styles is 
 * difficult to be overriden.
 *
 * This file depends on scss variables from `settings.select` file located in:
 * [ settings/_settings.select.scss ]
 */

.select2-container--default .select2-selection--single {
	@include linear-gradient(to bottom, $select-background-color);
	height: $select-height;
	border: $select-border-width solid $select-border-color;
	border-radius: $base-border-radius;
	color: $select-color;
	font-size: $select-font-size;
	font-weight: $select-font-weight;
	outline: 0;
}

.select2-container--default .select2-selection--single[aria-expanded="true"],
.select2-container--default .select2-selection--multiple[aria-expanded="true"] {
	outline: none;
	border: $select-border-width solid $select-hover-border-color;
	border-bottom-left-radius: $base-border-radius !important;
	border-bottom-right-radius: $base-border-radius !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: $select-height;
	padding-right: $spacer-small;
	padding-left: $spacer-small;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: $select-height;
}

.select2-results {
	border-radius: $base-border-radius;
	border: $base-border-width solid $base-border-color;
}

.select2-dropdown {
	margin: 5px 0;
    background-color: $dropdown-menu-background-color;
    border: $base-border-width solid $base-border-color;
    border-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
    border-top-left-radius: $base-border-radius;
}

.select2-container--open .select2-dropdown--below {
	border-top-right-radius: $base-border-radius;
    border-top-left-radius: $base-border-radius;
}

.select2-container--default .select2-results>.select2-results__options {
	border-radius: $base-border-radius;
}

.select2-results__option {
	padding: $spacer-xsmall $spacer-small;
	font-size: $text-font-size;

	& + .select2-results__option {
		border-top: $base-border-width solid $base-border-color;
	}
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $white;
	color: $link-color;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #F1F4F8;
	color: $link-color;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 1px;
	right: 1px;
	width: 20px; 

	&::after {
		display: inline-block;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		margin-left: 1.25rem;
		padding: 0.5rem 8px;
		border-color: inherit;
		color: inherit;
		font-family: $base-icon-font;
		content: "\f107"; // fa-angle-down
	}
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	display: none;
}


/**
 * Search Field
 */

.select2-search--dropdown {
	position: relative;
	padding: 5px;

	&::after {
		display: inline-block;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		margin-left: 1.25rem;
		padding: 10px 18px;
		border-color: inherit;
		color: #CED0DA;
		font-family: $base-icon-font;
		content: "\f002"; // fa-search
	}
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: $base-border-width solid $base-border-color;
	border-radius: $base-border-radius;
	padding: 8px 15px;
	outline: none;
	color: #354052;
	font-size: 14px;
}



/**
 * Multiple Selection
 */

.select2-container--default .select2-selection--multiple {
	display: flex;
	align-items: center;
	padding-bottom: 3px;
	background-color: $white;
	border-color: #DFE3E9;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: 1px solid #2ea2f8;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	padding: 0 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	display: flex;
	align-items: center;
	position: relative;
	margin-right: 3px;
    margin-top: 3px;
	padding: 4px 30px 4px 12px;
	background-color: #F1F4F8;
	border-color: #D5DCE6;
	color: #354052;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	position: absolute;
	right: 10px;
	color: #C7D2E0;
	font-size: 18px;
}

/**
 * Disabled Select
 */

.select2-container--default.select2-container--disabled .select2-selection--single {
	border-color: $select-disabled-border-color;
	background: $select-disabled-background-color;
	background-color: $select-disabled-background-color;
	color: $select-disabled-color;
}



