/* ==========================================================================
   #GRAPH CARD
   ========================================================================== */

.c-graph-card {
	margin: 0 0 $spacer;
	border: $base-border-width solid $grey-300;
	border-radius: $base-border-radius;
	background-color: $base-background-color;
	overflow: hidden;
}

.c-graph-card__content {
	padding: $spacer $spacer 0;
}

.c-graph-card__title {
	margin: 0;
	font-size: $h5-font-size;
}

.c-graph-card__date {
	margin: 0 0 $spacer-xsmall;
	color: $grey-800;
	font-size: $text-small-font-size;
}

.c-graph-card__number {
	margin: 0;
	color: $gunmetal-200;
	font-size: rem(40px);
	font-weight: $font-weight-light;
}

.c-graph-card__status {
	margin: 0;
	color: $grey-800;
	font-size: $text-font-size;
}

.c-graph-card__chart {
	position: relative;
	margin: 0;
	padding: $spacer;
}

.c-graph-card__footer {
	height: 100%;
	padding: $spacer-small $spacer;
	border-top: $base-border-color solid $base-border-width;
	background-color: #FAFBFC;
}




