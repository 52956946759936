///* ========================================================================
//   #RANGE
//   ======================================================================== */

$range-thumb-width: 10px;
$range-thumb-height: 10px;

$range-track-height: 2px;
$range-track-border-radius: 1px;
$range-track-background-color: #CED0DA;
