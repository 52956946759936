///* ========================================================================
//   #TOGGLE
//   ======================================================================== */

//
// 1. Height of toggle button 'c-toggle__btn' should equal the height if its 
//    container `c-toggle`.
//
// 2. Label's `line-height` should equal its `height` for centering its 
//    text vertically.
// 

$toggle-height: 45px; // [1]


$toggle-btn-width: 50%;
$toggle-btn-height: $toggle-height; // [1]
$toggle-btn-border-width: 1px;
$toggle-btn-border-color: #E7E9ED;
$toggle-btn-border-radius: $base-border-radius;

$toggle-btn-font-size: 14px;
$toggle-btn-color: #565F6D;
$toggle-btn-background-color: #FAFBFC;

$toggle-btn-active-background-color: #39B54A;
$toggle-btn-active-border-color: #249533;
$toggle-btn-active-color: $white;


$toggle-label-width: 100%;
$toggle-label-height: $toggle-height; // [2]
$toggle-label-line-height: $toggle-label-height; // [2]

