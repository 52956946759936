///* ========================================================================
//   #BADGES
//   ======================================================================== */

$badge-padding: 4px 12px;
$badge-small-padding: 4px 10px;
$badge-xsmall-padding: 2px 5px;

$badge-border-radius: $base-border-radius;

$badge-small-font-size: 10px;
$badge-font-size: $text-small-font-size;
$badge-font-weight: $font-weight-bold;
$badge-color: $white;

$badge-success-background-color: $color-success;
$badge-info-background-color: $color-info;
$badge-danger-background-color: $color-danger;
$badge-warning-background-color: $color-warning;
$badge-primary-background-color: $gunmetal-100;
$badge-secondary-background-color: $grey-600;

