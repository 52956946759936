///* ========================================================================
//   #DIVIDER
//   ======================================================================== */

$divider-color: $grey-800;
$divider-font-size: $text-small-font-size;
$divider-font-weight: $font-weight-normal;

$divider-line-height: 1px;
$divider-line-background-color: $grey-400;

