/* ==========================================================================
   #INPUTS
   ========================================================================== */

.c-input {
	display: block;
	width: 100%;
	margin: 0;
	padding: $input-padding;
	transition: all .3s;
	border: $input-border-width solid $input-border-color;
	border-radius: $input-border-radius;
	color: $input-focus-color;
	font-size: $input-font-size;
	font-weight: $input-font-weight;
	resize: none;

	&::placeholder {
		transition: opacity 0.3s;
		color: $input-placeholder-color;
		font-weight: $input-placeholder-font-weight;
		opacity: 0.5;
	}

	&:focus {
		border-color: $input-focus-border-color;
		outline: none;

		&::placeholder {
			opacity: 0.2;
		}
	}

	&.is-disabled,
	&:disabled {
		border-color: $input-disabled-border-color;
		background-color: $input-disabled-background-color;
		color: $input-disabled-color;
		cursor: not-allowed;
	}
}


// sass-lint:disable no-qualifying-elements
textarea.c-input {
	min-height: 90px;
	font-size: $textarea-font-size;
	font-weight: $textarea-font-weight;
}
// sass-lint:enable no-qualifying-elements


/* State variants
   ========================================================================== */

.c-input--success {
	border-color: $input-success-border-color;

	&:focus {
		border-color: $input-success-border-color;
	}
}

.c-input--warning {
	border-color: $input-warning-border-color;

	&:focus {
		border-color: $input-warning-border-color;
	}
}

.c-input--danger {
	border-color: $input-danger-border-color;
	color: $input-danger-color;

	&:focus {
		border-color: $input-danger-border-color;
		color: $input-danger-color;
	}
}
