/* ==========================================================================
   #CHART
   ========================================================================== */

//
// DEVELOPER NOTE: UNDER DEVELOPMENT!!
//
/**
 * This works as a wrapper for charts.
 */

.c-chart-container {
	position: relative;
	height: 95px;
}

.c-chart__title {
	margin: 0;
	color: $color-mute;
	font-size: $text-small-font-size;
}

.u-padding {
	padding: 0 $spacer;
}
