/* ==========================================================================
   #ALERTS
   ========================================================================== */

/**
 * 1. Position close icon (x) based on alert padding.
 */

.c-alert {
	display: flex;
	position: relative;
	width: 100%;
	margin: $alert-margin;
	padding: $alert-padding;
	transition: all 0.3s;
	border-radius: $alert-border-radius;
	color: $alert-color;

	.c-close {
		position: absolute;
		top: $alert-padding; /* [1] */
		right: $alert-padding; /* [1] */
	}
}

/**
 * 1. Align icon with text properly.
 */
.c-alert__icon {
	position: relative;
	bottom: -4px; /* [1] */
	margin-right: $alert-icon-margin-right;
	font-size: $alert-icon-font-size;
	opacity: $alert-icon-opacity;
}

/* Style variants
   ========================================================================== */

.c-alert--success {
	background-color: $alert-success-background-color;
}

.c-alert--info {
	background-color: $alert-info-background-color;
}

.c-alert--warning {
	background-color: $alert-warning-background-color;
}

.c-alert--danger {
	background-color: $alert-danger-background-color;
}
