/* ==========================================================================
   #CARD
   ========================================================================== */

/**
 * A simple component to apply card-like style, used with genearal widgets that 
 * have sub-components in it.
 */

.c-card {
	position: relative;
	border: $base-border-width solid $base-border-color;
	border-radius: $base-border-radius;
	background-color: $base-background-color;
}

.c-card__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: rem(25px) $spacer;
}

.c-card__title {
	margin: 0;
	font-size: $h5-font-size;
}

.c-card__meta {
	color: $color-mute;
	font-size: $text-small-font-size;
}

.c-card__icon {
	position: absolute;
	top: $spacer;
	right: $spacer;
}
