///* ========================================================================
//   #NOTIFICATIONS
//   ======================================================================== */

$notification-icon-color: #7F8FA4;
$notification-icon-font-size: 20px;

$notification-number-width: 20px;
$notification-number-height: 20px;

$notification-number-padding: 0 5px;
$notification-number-border-radius: 20px;

$notification-number-background-color: #FF7800;
$notification-number-color: $white;
$notification-number-font-size: $text-small-font-size;
$notification-number-line-height: 20px;
