///* ========================================================================
//   #TOOLBAR
//   ======================================================================== */

$toolbar-padding: 20px 30px;
$toolbar-background-color: $white;
$toolbar-border-color: #E6EAEE;
$toolbar-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);


$toolbar-title-color: #354052;
$toolbar-title-font-size: 18px;


$toolbar-divider-width: 1px;
$toolbar-divider-height: 15px;
$toolbar-divider-margin: 0 13px;
$toolbar-divider-background-color: #DFE3E9;


$toolbar-meta-font-size: 14px;
$toolbar-meta-color: #7F8FA4;


$toolbar-icon-color: #CED0DA;
$toolbar-icon-font-size: 18px;

$toolbar-link-color: #7F8FA4;
$toolbar-link-font-size: 14px;




