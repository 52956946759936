///* ========================================================================
//   #ALERTS
//   ======================================================================== */


$alert-margin: 0 0 rem(15px) 0;
$alert-padding: rem(12px);
$alert-border-radius: $base-border-radius;
$alert-color: $white;

$alert-icon-margin-right: rem(10px);
$alert-icon-opacity: 0.7;
$alert-icon-font-size: rem(18px);

$alert-success-background-color: $color-success;
$alert-info-background-color: $color-info;
$alert-warning-background-color: $color-warning;
$alert-danger-background-color: $color-danger;
