// =============================================================================
// #PLANS 
// =============================================================================

/**
  * A wrapper for plans
  */
.c-plans {
	position: relative;
	width: 910px;
	margin: 0 auto;
	padding: 40px 0 0;
	border-radius: $base-border-radius;
	background-color: $white;
	text-align: center;
	overflow: hidden;
}

.c-plans__header {
	width: 55%;
	margin: 0 auto;
}

.c-plans__close {
	position: absolute;
	top: $spacer;
	right: $spacer;
	color: $gunmetal-500;
	font-size: 18px;
	opacity: 0.75;

	&:hover {
		opacity: 1;
	}
}

.c-plans__title {
	margin: 0 0 5px;
}

.c-plans__description {
	margin: 0 0 50px;
	color: #7F8FA4;
}

.c-plans__body {
	padding: 0 60px;
}

.c-plans__footer {
	padding: 17px 0;
	background-color: #F0F3F8;
	box-shadow: 0 -1px 0 0 #E6EAEE;
}

/**
  * The actual plan
  */
.c-plan {
	margin: 0 5px 30px;
	padding: 40px 20px 25px;
	border: 1px solid #DFE3E9;
	border-radius: $base-border-radius;
	background-color: transparent;
	text-align: left;
}

.c-plan__img {
	position: absolute;
	top: -20px;
}

.c-plan__title {
	margin: 0;
	color: #7383A1;
	font-size: 12px;
	text-transform: uppercase;
}

.c-plan__price {
	margin: 0;
	font-size: 28px;
}

.c-plan__note {
	margin: 0;
	font-size: $text-small-font-size;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}

.c-plan__divider {
	display: block;
	width: 100%;
	height: 1px;
	margin: 15px 0;
	background-color: #DFE3E9;
}

.c-plan__feature {
	margin-bottom: 5px;
	color: #7E8DA9;
	font-size: $text-font-size;
}





















