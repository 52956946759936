/* ==========================================================================
   #LOGIN
   ========================================================================== */

.c-login-wrapper {
	position: relative;
	height: 100%;
}

.c-login {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 450px;
	margin: auto;
	transform: translate(-50%, -50%);
	border: $base-border-width solid $base-border-color;
	border-radius: $base-border-radius;
	background-color: $base-background-color;

	@include respond-to($breakpoint-mobile) {
		width: 95%;
		margin-top: $spacer-large;
		margin-bottom: $spacer-xlarge;
	}
}

.c-login__head {
	position: relative;
	padding: rem(50px) 0 $spacer;
	border-bottom: $base-border-width solid $base-border-color;
	border-top-left-radius: $base-border-radius;
	border-top-right-radius: $base-border-radius;
	background-color: #F8FAFC;
}

.c-login__brand {
	display: block;
	position: absolute;
	top: -35px;
	right: 0;
	left: 0;
	width: 70px;
	height: 70px;
	margin: 0 auto;
}

.c-login__title {
	margin: 0;
	font-size: 24px;
	text-align: center;
}

.c-login__content {
	padding: $spacer;
}

.c-login__social {
	display: flex;
	justify-content: space-between;
}

.c-login__social-icon {
	flex-basis: 18%;
	height: 70px;
	border-radius: $base-border-radius;
	background-color: $gunmetal-200;
	color: $white;
	font-size: 24px;
	line-height: 70px;
	text-align: center;

	&:hover {
		color: $white;
	}

	@include respond-to($breakpoint-mobile) {
		flex-basis: 23%;
	}
}

.c-login__footer {
	@include clearfix;
	position: absolute;
	bottom: -40px;
	width: 100%;
}

.c-login__footer-link {
	color: $color-mute;
	font-size: $text-font-size;
}
